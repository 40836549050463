<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Master Akun</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              @keyup.enter="searching()"
              append-icon="mdi-magnify"
              label="Cari"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Tambah </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <label for="">Kategori Akun</label>
                        <v-select
                          v-model="editedItem.kategori"
                          :items="kategori"
                          item-text="text"
                          item-value="value"
                          persistent-hint
                          return-object
                          single-line
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.nomor" label="Nomor Akun *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="8" md="8">
                        <v-text-field v-model="editedItem.nama" label="Nama Akun *"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close"> Batal </v-btn>
                  <v-btn color="primary" @click="save"> Simpan </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeDelete">Cancel</v-btn>
                  <v-btn color="secondary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
          </v-toolbar>
        </template>
        <template v-slot:item.no="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-slot:item.aksi="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reload</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Nomor Akun', value: 'nomor', sortable: false },
      { text: 'Nama Akun', value: 'nama', sortable: false },
      { text: 'Kategori Akun', value: 'kategori', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: { itemsPerPage: -1 },
    editedIndex: -1,
    editedItem: {
      nomor: '',
      nama: '',
      kategori: { text: '', value: '' },
    },
    defaultItem: {
      nomor: '',
      nama: '',
      kategori: { text: '', value: '' },
    },
    akuns: [],
    search: '',
    kategori: [
      { text: '1 Aset', value: '1' },
      { text: '2  Liabilitas', value: '2' },
      { text: '3 Ekuitas', value: '3' },
      { text: '4 Pendapatan', value: '4' },
      { text: '5 Biaya/Beban', value: '5' },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Akun' : 'Edit Akun'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    // initialize() {
    // },
    getDataFromApi() {
      this.loading = true
      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      // const form = new FormData()
      // form.append('search', this.search)
      // form.append('page', page)
      // form.append('itemsPerPage', itemsPerPage)
      axios
        .get(`${apiRoot}/api/Akun/getAll`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
            this.loading = false
          } else {
            alert(response.data.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },
    editItem(item) {
      // console.log(item)
      this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.editedItem.id = item.id
      this.editedItem.nomor = item.nomor
      this.editedItem.nama = item.nama
      this.editedItem.kategori.value = item.id_kategori_akun
      this.editedItem.kategori.text = item.kategori
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        this.desserts[this.editedIndex] = Object.assign({}, this.editedItem)

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('nomor', this.editedItem.nomor)
        form.append('nama', this.editedItem.nama)
        form.append('id_kategori_akun', this.editedItem.kategori.value)
        axios
          .post(`${apiRoot}/api/Akun/update`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('nomor', this.editedItem.nomor)
        form.append('nama', this.editedItem.nama)
        form.append('id_kategori_akun', this.editedItem.kategori.value)
        axios
          .post(`${apiRoot}/api/Akun/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>
