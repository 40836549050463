var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Master Akun")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searching()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Tambah ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("Kategori Akun")]),_c('v-select',{attrs:{"items":_vm.kategori,"item-text":"text","item-value":"value","persistent-hint":"","return-object":"","single-line":"","dense":"","outlined":""},model:{value:(_vm.editedItem.kategori),callback:function ($$v) {_vm.$set(_vm.editedItem, "kategori", $$v)},expression:"editedItem.kategori"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nomor Akun *"},model:{value:(_vm.editedItem.nomor),callback:function ($$v) {_vm.$set(_vm.editedItem, "nomor", $$v)},expression:"editedItem.nomor"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-text-field',{attrs:{"label":"Nama Akun *"},model:{value:(_vm.editedItem.nama),callback:function ($$v) {_vm.$set(_vm.editedItem, "nama", $$v)},expression:"editedItem.nama"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Simpan ")])],1)],1)],1)],1)]},proxy:true},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reload")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }